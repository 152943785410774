import React from 'react'
import styled from 'styled-components'
import { primary } from '../../../../src/constants/theme'

const setPadding = (props) => {
    let top = '5rem'
    let bottom = '5rem'
    let right = '2rem'
    let left = '2rem'

    if (props.highPadding) {
        top = '8rem'
        bottom = '8rem'
    }
    if (props.narrowPadding) {
        right = '1rem'
        left = '1rem'
    }
    if (props.paddingTop) {
        top = props.paddingTop
    }
    return `${top} ${right} ${bottom} ${left}`
}

const SectionContainer = styled.section`
    background: ${(props) => props.background};
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    padding: ${(props) => setPadding};
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    h1 {
        font-size: 2.5rem;
        padding-top: 5px;
        text-align: center;
    }
    h2 {
        color: ${primary};
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 auto 1rem;
        text-align: center;
        width: 100%;
    }
    h3 {
        white-space: pre-line;
        font-weight: 600;
    }

    &:nth-child(odd) {
        background-color: 'transparent';
    }
    @media (min-width: 992px) {
        //min-height: 800px;
        padding: ${(props) => setPadding};
    }
`
const SectionContent = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: ${(props) => (props.narrow ? '750px' : '960px')};
    > p {
        font-size: 1.37rem;
        padding: 1.5rem 0.1rem;
        a {
            font-size: 1.37rem;
        }
    }
    > h2,
    > p,
    .big-text {
        text-align: center;
    }
    ul {
        li {
            font-size: 1.37rem;
        }
    }

    @media (min-width: 992px) {
        > p {
            font-size: 1.45rem;
        }
        > h2,
        > p {
            text-align: ${(props) => (props.centerText ? 'center' : 'left')};
        }
    }
    @media (max-width: 1024px) {
        max-width: 800px;
    }
    @media (max-width: 768px) {
        max-width: 100%;
    }
`

class Section extends React.Component {
    render() {
        return (
            <SectionContainer {...this.props}>
                <SectionContent
                    narrow={this.props.narrow}
                    centerText={this.props.centerText}
                >
                    {this.props.children}
                </SectionContent>
            </SectionContainer>
        )
    }
}

export default Section

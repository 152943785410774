import React from 'react'
import Img from 'gatsby-image'
//import Button from '../Button'
import { Link } from 'gatsby'
import styled from 'styled-components'

const CTA = styled.div`
    margin: 10px auto 20px;
    max-width: 400px;
    @media (max-width: 767px) {
        margin: 0 auto 10px;
    }
`

const Image = ({ fluid, title, alt, withCTA }) => {
    if (withCTA) {
        return (
            <div>
                {/*<CTA>
                    <Link to="/demande/">
                        <Button>Trouver mon avocat</Button>
                    </Link>
        </CTA> */}
                <Img
                    margin={{ marginTop: '20px' }}
                    fluid={fluid}
                    title={title}
                    alt={alt}
                />
            </div>
        )
    }
    return <Img fluid={fluid} title={title} alt={alt} />
}

export default Image

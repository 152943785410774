import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="Accusation de garde et contrôle d’un véhicule en état d’ébriété : comment se défendre avec un avocat?"
        description="Visé par une accusation de garde et contrôle en état d’ébriété? Consultez un avocat spécialisé pour vous défendre!">
        <p>
            <strong>
                L’alcool au volant, ce n’est pas seulement illégal lorsqu’un
                roule avec son véhicule.{' '}
            </strong>
        </p>
        <p>
            Le Code criminel interdit également aux conducteurs de se trouver
            derrière le volant de leur automobile s’ils sont en état d’ébriété.
            C’est ce qu’on appelle l’infraction de « garde et contrôle d’un
            véhicule en état d’ébriété »!{' '}
        </p>
        <p>
            <Img
                fluid={data.img0.childImageSharp.fluid}
                title="Accusation de garde et contrôle"
                alt="Une image contenant personne"
            />
        </p>
        <p>
            Un conducteur ivre peut être visé par une telle accusation même s’il
            n’a pas mis le moteur en marche, et même si le véhicule était à
            l’arrêt complet. Vous êtes la cible d’une telle accusation reliée à
            l’alcool au volant? Ne tardez pas à vous allier d’un avocat
            compétent pour vous défendre.{' '}
        </p>
        <p>
            <Link to="https://avocatsalcoolauvolant.ca/">
                Avocatsalcoolauvolant.ca
            </Link>{' '}
            vous explique en détail l’accusation de garde et contrôle, et on
            vous réfère gratuitement à un avocat pour vous représenter. C’est
            sans aucun engagement de votre part!{' '}
        </p>
        <h2>
            Qu’est-ce qu’une accusation de garde et contrôle d’un véhicule en
            état d’ébriété?
        </h2>
        <p>
            Le Code criminel définit l’infraction de garde et contrôle d’un
            véhicule comme le fait d’être responsable de ce même véhicule alors
            que les facultés sont affaiblies par l’alcool. Le conducteur doit
            donc surveiller le véhicule et assumer une certaine maîtrise de
            celui-ci et de ses accessoires.<strong> </strong>
        </p>
        <p>
            L’exemple « classique » de cette infraction est celui du conducteur
            ivre qui s’est endormi derrière le volant de son véhicule stationné
            au lieu d’appeler un taxi (ou en l’attendant). Il n’est même pas
            nécessaire que le moteur du véhicule soit en marche pour être en
            présence d’une infraction de garde et contrôle.
        </p>
        <p>
            De plus, la loi prévoit qu’une personne qui occupe la place du
            conducteur est présumée avoir la garde et le contrôle du véhicule,
            ce qui complique la défense du conducteur en état d’ébriété.
            <strong> </strong>
        </p>
        <p>
            <strong>
                Voici quelques exemples de situations qui peuvent constituer une
                accusation de garde et contrôle d’un véhicule en état
                d’ébriété :{' '}
            </strong>
        </p>
        <ul>
            <li>
                <em>
                    Une personne s’endort derrière le volant d’un véhicule
                    immobilisé en état d’ébriété.{' '}
                </em>
            </li>
            <li>
                <em>
                    Dormir ou attendre sur la banquette arrière d’une voiture en
                    état d’ébriété.{' '}
                </em>
            </li>
            <li>
                <em>
                    Se trouver à l’extérieur du véhicule en état d’ébriété, en
                    ayant la garde et le contrôle du véhicule.
                </em>
            </li>
        </ul>
        <p>
            <strong>
                Pour être reconnu coupable de cette infraction, il doit y avoir
                un risque réaliste que le véhicule soit mis en mouvement, et
                qu’il pose un danger.{' '}
            </strong>
        </p>
        <p>
            Le fait d’avoir démarré le moteur, d’avoir les clés en sa possession
            et autres actes similaires sont des signes que le conducteur assume
            effectivement la garde et le contrôle du véhicule.{' '}
        </p>
        <p>
            <strong>Mais pas si vite! </strong>Une situation de garde et
            contrôle se trouve souvent dans une zone grise, et des moyens de
            défense existent pour se défaire d’une telle accusation criminelle.{' '}
        </p>
        <h2>
            Quelles défenses peuvent être soulevées contre une infraction de
            garde et contrôle?{' '}
        </h2>
        <p>
            Le simple fait qu’une personne soit assise dans son véhicule à la
            place du conducteur alors qu’elle est en état d’ébriété crée une
            présomption de garde et contrôle. Mais cette présomption n’est pas
            toujours suffisante pour obtenir une condamnation, puisque l’accusé
            peut invoquer un moyen de défense légitime.{' '}
        </p>
        <p>
            <Img
                fluid={data.img1.childImageSharp.fluid}
                title="Défenses accusation de garde et contrôle"
                alt="Une image contenant voiture, homme, personne, miroir"
            />
        </p>
        <p>
            <strong>
                Voici les principales défenses invoquées contre une accusation
                de garde et contrôle d’un véhicule en état d’ébriété!{' '}
            </strong>
        </p>
        <h3>La défense du plan alternatif </h3>
        <p>
            Un conducteur qui invoque la défense du plan alternatif pourra être
            exonéré s’il démontre qu’il s’est servi du véhicule comme refuge
            temporaire en l’attente d’un taxi ou d’un autre moyen de transport.{' '}
        </p>
        <p>
            Par exemple, une personne qui constate son incapacité de conduire et
            qui attend l’arrivée d’un transport dans son véhicule pourrait faire
            la preuve d’un plan alternatif. Ce plan doit être bien arrêté, et
            prouvé par des éléments concrets.
        </p>
        <h3>La défense d’intoxication involontaire </h3>
        <p>
            Une infraction criminelle nécessite une intention de la part du
            contrevenant. Le conducteur qui est intoxiqué involontairement et
            contre son gré pourra soulever une défense d’intoxication
            involontaire contre son accusation de garde et contrôle.{' '}
        </p>
        <p>
            Il s’agit cependant d’une question de fait qui devra être démontrée
            eu égard aux circonstances de l’infraction.{' '}
        </p>
        <h3>La défense d’absence de risque de mettre le véhicule en marche</h3>
        <p>
            Il est possible de se défaire d’une accusation de garde et contrôle
            si la preuve est faite qu’il n’existait pas de risque que le
            véhicule soit mis en marche. Par exemple, un conducteur endormi sur
            le siège arrière d’un véhicule et dont les clés ne sont pas dans le
            contact pourrait invoquer avec succès l’absence de risque de faire
            fonctionner l’automobile.{' '}
        </p>
        <p>
            <strong>
                Laquelle de ces défenses vous extirpera des griffes de la
                justice? Seul un avocat spécialisé en matière d’alcool au volant
                saura vous le dire!{' '}
            </strong>
        </p>
        <p>
            Ce sont les faits spécifiques à votre infraction qui dictent la
            défense appropriée à soulever, et c’est pourquoi il est essentiel
            qu’un avocat se penche sur votre dossier rapidement pour maximiser
            vos chances de succès.
        </p>
        <h2>
            Les conséquences reliées à une accusation de garde et contrôle au
            Québec!{' '}
        </h2>
        <p>
            Les conséquences découlant d’une infraction de garde et contrôle
            sont très similaires à celles d’une accusation d’alcool au volant.
            Des amendes salées et un casier judiciaire font partie des sentences
            à anticiper en cas de verdict de culpabilité!
        </p>
        <p>
            <Img
                fluid={data.img2.childImageSharp.fluid}
                title="Conséquences d’une accusation de garde et contrôle"
                alt="Une image contenant personne"
            />
        </p>
        <p>
            <strong>
                Comme une accusation de garde et contrôle peut être lourde de
                conséquences, voici les sentences prévues pour une telle
                infraction!
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>
                        1<sup>ère</sup> infraction{' '}
                    </p>
                    <p>(Garde et contrôle/Alcool au volant)</p>
                </td>
                <td>
                    <p>Casier judiciaire </p>
                    <p>Amende minimale de 1000$ </p>
                    <p>Interdiction de conduire de 12 mois</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        2<sup>ième</sup> infraction{' '}
                    </p>
                    <p>(Garde et contrôle/Alcool au volant)</p>
                </td>
                <td>
                    <p>Emprisonnement minimal de 30 jours</p>
                </td>
            </tr>
        </table>
        <p>
            <strong>
                Dès votre arrestation par les policiers, votre permis sera
                également suspendu pour une période 90 jours.{' '}
            </strong>
        </p>
        <p>
            Avant même que vous ne soyez reconnu coupable devant le tribunal, la
            SAAQ vous imposera une suspension de permis automatique de 90 jours
            si vous êtes arrêté pour une infraction de garde et contrôle.{' '}
        </p>
        <p>
            Il est impossible de déroger à cette suspension ou d’obtenir un
            permis restreint, à moins de contester la décision de la SAAQ auprès
            du Tribunal administratif. Rares sont les histoires de succès pour
            de tels dossiers, cependant!{' '}
        </p>
        <p>
            <strong>
                Une accusation de garde et contrôle vous obligera également à
                débourser des frais.{' '}
            </strong>
        </p>
        <p>
            Si vous êtes reconnu coupable, vous pourriez être contraint de vous
            soumettre au Programme d’évaluation et de réduction du risque de
            conduite avec les facultés affaiblies, lequel vient avec des frais
            de 344$ à 816$ selon l’évaluation exigée par la SAAQ.{' '}
        </p>
        <p>
            <strong>Vous aimeriez obtenir un permis restreint? </strong>La pose
            d’un antidémarreur éthylométrique pourra vous être exigée, ce qui
            vient avec des frais mensuels et des coûts d’installation qui
            dépasseront les quelques centaines de dollars.{' '}
        </p>
        <p>
            <strong>
                De telles conséquences ne vous seront imposées que si vous êtes
                reconnu coupable – Dépêchez-vous à trouver un avocat compétent
                pour vous défendre!
            </strong>
        </p>
        <h2>
            Pourquoi contacter rapidement un avocat spécialisé en matière
            d’alcool au volant?
        </h2>
        <p>
            Les infractions reliées à l’alcool au volant possèdent leur lot de
            subtilités, et une certaine expertise de la part de l’avocat. Comme
            l’absolution n’est pas une peine envisageable pour les dossiers de
            conduite avec les facultés affaiblies, il est essentiel d’obtenir un
            acquittement ou l’abandon des accusations.
        </p>
        <p>
            <Img
                fluid={data.img3.childImageSharp.fluid}
                title="Avocat spécialisé alcool au volant”"
                alt="Une image contenant intérieur"
            />
        </p>
        <p>
            <strong>
                Et les avocats spécialisés en matière d’alcool au volant sont
                les mieux placés pour vous aider. Pour vous défendre contre une
                accusation de garde et contrôle, votre avocat est en mesure de :
            </strong>
        </p>
        <h3>Déterminer le bon moyen de défense </h3>
        <p>
            <em>
                Plaiderez-vous la défense d’absence de risque, l’intoxication
                volontaire, ou encore, la mise en place d’un plan alternatif?
                Seul un avocat compétent saura répondre à cette question!
            </em>
        </p>
        <h3>Analyser les faits et vos chances de succès </h3>
        <p>
            <em>
                Ce ne sont pas toutes les situations de garde et contrôle qui
                viennent avec les mêmes chances de succès. La première tâche de
                l’avocat est d’analyser les faits de votre histoire, et de
                déterminer s’il vaut mieux négocier un plaidoyer avec le
                procureur plutôt que d’affronter un procès.{' '}
            </em>
        </p>
        <h3>Analyser la preuve récoltée par les policiers </h3>
        <p>
            <em>
                Les policiers sont soumis à des règles précises lorsqu’ils
                interceptent un conducteur en état d’ébriété. Le non-respect des
                procédures, notamment au niveau de l’échantillon d’haleine, peut
                constituer une défense permettant d’obtenir un acquittement ou
                encore l’abandon des accusations.{' '}
            </em>
        </p>
        <h3>Défendre vos intérêts devant le tribunal </h3>
        <p>
            <em>
                Lorsque la voie du procès est inévitable, votre avocat
                criminaliste défendra vos intérêts face au juge, et tentera
                d’obtenir un acquittement en invoquant le moyen de défense le
                plus solide à votre disposition.{' '}
            </em>
        </p>
        <p>
            <em> </em>
        </p>
        <p>
            Le rôle de la plateforme Avocatsalcoolauvolant.ca est justement de
            dénicher le meilleur avocat pour vous défendre contre une accusation
            de garde et contrôle ou d’alcool au volant.{' '}
        </p>
        <p>
            <strong>
                Pour entrer en contact gratuitement avec un tel professionnel,
                il ne vous reste qu’à remplir notre formulaire, et on s’occupe
                de trouver votre avocat!{' '}
            </strong>
        </p>
        <h2>
            Trouvez le bon avocat pour vous défendre contre une accusation de
            garde et contrôle!
        </h2>
        <p>
            Être reconnu coupable d’une infraction de garde et contrôle d’un
            véhicule avec les facultés affaiblies vous laissera avec un casier
            judiciaire, une amende salée, un permis de conduire suspendu et un
            antidémarreur éthylométrique…{' '}
        </p>
        <p>
            N’attendez pas d’être victime d’un tel châtiment sans vous défendre;
            contactez un avocat spécialisé en infractions d’alcool au volant.{' '}
        </p>
        <p>
            <strong>
                Notre rôle est justement de vous dénicher un tel professionnel
                du droit criminel pour vous représenter.
            </strong>
        </p>
        <p>
            <strong>
                Remplissez notre formulaire en moins de 2 minutes, et on
                s’occupe de vous référer aux bons avocats, sans aucun
                engagement!{' '}
            </strong>
        </p>
    </SeoPage>
)

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "accusation-garde-controle-alcool/accusation-garde-controle-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "accusation-garde-controle-alcool/defenses-accusation-garde-contrôle.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "accusation-garde-controle-alcool/consequences-accusation-garde-contrôle.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "accusation-garde-controle-alcool/avocat-specialise-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
